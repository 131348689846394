<template>
  <div>
    <div class="content-wrapper">
      <Header
        title="Webhooks"
      />

      <section class="d-flex flex-row justify-content-center align-items-center">
        <LeftTable
          :requests="allRequests"
          @select-request="selectWebhook"
          @delete-request="deleteWebhook"
        />

        <RequestForm
          :spinner="spinner"
          :request="request"
          @create-webhook="createWebhook"
          @update-webhook="updateWebhook"
          @clear-webhook="clearWebhook"
        />
      </section>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    LeftTable: () => import("./components/LeftTable.vue"),
    RequestForm: () => import("./components/RequestForm.vue"),
    Header: () => import("./components/Header.vue")
  },

  data() {
    return {
      request: {
        _id: '',
        event: 'create_company',
        url: '',
        method: 'GET',
        headers: [
          { 
            key: 'Content-Type',
            value: 'application/json'
          },
        ],
        body: [
          {
            key: '',
            value: ''
          },
        ],
        body_type: 'json'
      },
      allRequests: [],
      spinner: false
    }
  },

  methods: {
    createWebhook() {
      this.spinner = true;

      this.$tallos_admin_api.post('webhooks', {...this.request, employee: this.$store.getters.admin.id})
        .then(response => {
          this.clearWebhook();
          this.findWebhooks();

          this.$toasted.global.defaultSuccess({
            msg: "Webhook criado com sucesso."
          },{
            duration: 1000
          });
        })
        .catch(error => {
          this.$toasted.global.defaultError({
            msg: "Erro ao criar webhook."
          });
        })
    },

    findWebhooks() {
      this.$tallos_admin_api.get('webhooks')
        .then(response => {
          this.allRequests = response.data;

          this.$toasted.global.defaultSuccess({
            msg: "Webhooks encontrados."
          },{
            duration: 1000
          });
        })
        .catch(error => {
          this.$toasted.global.defaultError({
            msg: "Erro ao encontrar webhooks."
          });
        })
    },

    updateWebhook() {
      this.$tallos_admin_api.put('webhooks', this.request)
        .then(() => {
          this.clearWebhook();
          this.findWebhooks();

          this.$toasted.global.defaultSuccess({
            msg: "Webhook atualizado."
          },{
            duration: 1000
          });
        })
        .catch(error => {
          this.$toasted.global.defaultError({
            msg: "Erro ao atualizar webhook."
          });
        })
    },

    clearWebhook() {
      this.spinner = false;
      this.request = {
        _id: '',
        event: 'create_company',
        url: '',
        method: 'GET',
        headers: [
          { 
            key: 'Content-Type',
            value: 'application/json'
          },
        ],
        body: [
          {
            key: '',
            value: ''
          },
        ],
        body_type: 'json'
      }
    },

    selectWebhook(request) {
      this.request = request;
    },

    deleteWebhook(data) {
      this.$tallos_admin_api.delete(`webhooks/${data.request._id}`)
        .then(() => {
          this.$delete(this.allRequests, data.key);

          this.$toasted.global.defaultSuccess({
            msg: "Webhook removido."
          },{
            duration: 300
          });
        })
        .catch(error => {
          this.$toasted.global.defaultError({
            msg: "Erro ao remover webhook."
          });
        })
    }
  },

  mounted() {
    this.$tallos_admin_api.defaults.headers.common["Authorization"] = `Bearer ${this.$store.getters.tokenJWT}`;

    this.findWebhooks();
  }
}
</script>

<style>
.content-wrapper {
  background-color: #fff;
}

.content-out {
  border-bottom: 1px solid #0001
}

.section {
  background-color: #000;
}

.back-green {
  background-color: rgb(0, 252, 0);
}

.back-red {
  background-color: rgb(255, 0, 0);
}
</style>